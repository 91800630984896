import {
    LOAD_SALES_ORDER_LIST_REQUEST,
    LOAD_SALES_ORDER_LIST_SUCCESS,
    LOAD_SALES_ORDER_LIST_FAILURE,
    SHOW_EDIT_SALES_ORDER,
    SHOW_ADD_SALES_ORDER,
    SELECT_CURRENT_SALES_ORDER_REQUEST,
    SELECT_CURRENT_SALES_ORDER_SUCCESS,
    SELECT_CURRENT_SALES_ORDER_FAILURE,
    CANCEL_SALES_PRODUCT_RECORD,
    EDIT_SALES_PRODUCT_RECORD,
    DELETE_SALES_PRODUCT_RECORD,
    SAVE_SALES_PRODUCT_RECORD,
    UPDATE_READY_STATUS_REQUEST,
    UPDATE_READY_STATUS_SUCCESS,
    UPDATE_READY_STATUS_FAILURE,
    ADD_SALES_PRODUCT_RECORD,
    LOAD_PRODUCT_NAMES_REQUEST,
    LOAD_PRODUCT_NAMES_SUCCESS,
    LOAD_PRODUCT_NAMES_FAILURE,
    EDIT_CURRENT_SALES_ORDER_REQUEST,
    EDIT_CURRENT_SALES_ORDER_SUCCESS,
    EDIT_CURRENT_SALES_ORDER_FAILURE,
    ADD_CURRENT_SALES_ORDER_REQUEST,
    ADD_CURRENT_SALES_ORDER_SUCCESS,
    ADD_CURRENT_SALES_ORDER_FAILURE,
    DELETE_SALES_ORDER_REQUEST,
    DELETE_SALES_ORDER_SUCCESS,
    DELETE_SALES_ORDER_FAILURE,
} from './types';

import moment from 'moment';

const DEFAULT_STATE = {
    isLoading: {
        salesOrderList: false,
        currentSalesProduct: false,
        currentSalesOrder: false,
    },
    isEnabled: {
        addButton: true,
        addSalesProductButton: true,
    },
    salesOrderList : [],
    showEditSalesOrder: false,
    showAddSalesOrder: false,
    currentSalesOrder: {},
    currentSalesProduct: [],
    salesProductEditingKey: '',
    productList: [],
};

var newKey = 0;

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case DELETE_SALES_ORDER_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: true,
                }
            };

        case DELETE_SALES_ORDER_SUCCESS:
            var newSalesOrderList = state.salesOrderList.filter( o => o.key != action.payload );
            newSalesOrderList.sort( (a,b) => moment(b.date,"DD/MM/YYYY").diff(moment(a.date,"DD/MM/YYYY")) );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                },
                salesOrderList: newSalesOrderList
            };

        case DELETE_SALES_ORDER_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                }
            };



        case ADD_CURRENT_SALES_ORDER_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: true,
                }
            };

        case ADD_CURRENT_SALES_ORDER_SUCCESS:
            var newSalesOrderList = state.salesOrderList;
            newSalesOrderList.push ( action.payload );
            newSalesOrderList.sort( (a,b) => moment(b.date,"DD/MM/YYYY").diff(moment(a.date,"DD/MM/YYYY")) );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                },
                salesOrderList: newSalesOrderList
            };

        case ADD_CURRENT_SALES_ORDER_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                }
            };

        case EDIT_CURRENT_SALES_ORDER_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: true,
                }
            };

        case EDIT_CURRENT_SALES_ORDER_SUCCESS:
            var newSalesOrderList = state.salesOrderList.filter( o => o.key != action.payload.key );
            newSalesOrderList.push ( action.payload );
            newSalesOrderList.sort( (a,b) => moment(b.date,"DD/MM/YYYY").diff(moment(a.date,"DD/MM/YYYY")) );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                },
                salesOrderList: newSalesOrderList
            };

        case EDIT_CURRENT_SALES_ORDER_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                }
            };

        case LOAD_PRODUCT_NAMES_REQUEST:
            return state;

        case LOAD_PRODUCT_NAMES_SUCCESS:
            return {
                ...state,
                productList: action.payload
            };
        
        case LOAD_PRODUCT_NAMES_FAILURE:
            return state;

        case ADD_SALES_PRODUCT_RECORD:
            var newCurrentSalesProduct = state.currentSalesProduct;
            ++newKey;
            newCurrentSalesProduct.push({
                key: 'N'+newKey, 
                id: 'N'+newKey,
                description: '',
                quantity: '0',
                price: '0',
                discount: '0',
                total: '0',
                ready: 'No',
            });
            
            newCurrentSalesProduct.sort( (a,b) => (b.key - a.key));
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addSalesProductButton: false,
                },
                salesProductEditingKey: 'N'+newKey,
                currentSalesProduct: newCurrentSalesProduct,
            }

        case UPDATE_READY_STATUS_REQUEST:
                newCurrentSalesProduct = state.currentSalesProduct;
                for (var i=0;i<newCurrentSalesProduct.length;i++) {
                    if (newCurrentSalesProduct[i].id === action.id) {
                        newCurrentSalesProduct[i].ready = action.ready;
                    }
                }
                return {
                    ...state,
                    currentSalesProduct: newCurrentSalesProduct
                };
            
        case UPDATE_READY_STATUS_SUCCESS:
            return state;

        case UPDATE_READY_STATUS_FAILURE:
            newCurrentSalesProduct = state.currentSalesProduct;
            for (var i=0;i<newCurrentSalesProduct.length;i++) {
                if (newCurrentSalesProduct[i].id === action.payload.id) {
                    newCurrentSalesProduct[i].ready = action.payload.ready === 'Yes' ? 'No' : 'Yes';
                }
            }
            return {
                ...state,
                currentSalesProduct: newCurrentSalesProduct
            };
            
        case SAVE_SALES_PRODUCT_RECORD:
            //var newCurrentSalesProduct = state.currentSalesProduct.filter( o => o.key != 'N'+newKey && o.key != action.payload.key );
            var newCurrentSalesProduct = state.currentSalesProduct.filter( o => o.key != action.payload.key );
            newCurrentSalesProduct.push( action.payload );
            newCurrentSalesProduct.sort( (a,b) => (b.key - a.key));
            
            var sumTotal = 0;
            for (var i=0;i<newCurrentSalesProduct.length;i++){
                sumTotal += newCurrentSalesProduct[i].total;
            }

            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addSalesProductButton: true
                },
                salesProductEditingKey: '',
                currentSalesProduct: newCurrentSalesProduct,
                currentSalesOrder: {
                    ...state.currentSalesOrder,
                    //total: sumTotal * (1-state.currentSalesOrder.discount/100)
                    total: sumTotal
                }
            };
        
        case DELETE_SALES_PRODUCT_RECORD:
            var newCurrentSalesProduct = state.currentSalesProduct.filter( o => o.key != action.payload );
            var sumTotal = 0;
            for (var i=0;i<newCurrentSalesProduct.length;i++){
                sumTotal += newCurrentSalesProduct[i].total;
            }
            return {
                ...state,
                currentSalesProduct: newCurrentSalesProduct,
                currentSalesOrder: {
                    ...state.currentSalesOrder,
                    //total: sumTotal * (1-state.currentSalesOrder.discount/100)
                    total: sumTotal
                }
            };

        case EDIT_SALES_PRODUCT_RECORD:
            return {
                ...state,
                salesProductEditingKey: action.payload,
                isEnabled: {
                    ...state.isEnabled,
                    addSalesProductButton: false,
                }
            };
        
        case CANCEL_SALES_PRODUCT_RECORD:
            var newCurrentSalesProduct = state.currentSalesProduct.filter( o => o.key != 'N'+newKey );
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addSalesProductButton: true
                },
                salesProductEditingKey: '',
                currentSalesProduct: newCurrentSalesProduct,
            };

        case SELECT_CURRENT_SALES_ORDER_REQUEST:
            var currentSalesOrder = state.salesOrderList.filter( o => o.key === action.payload );
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentSalesProduct: true,
                    currentSalesOrder: true,
                },
                currentSalesOrder: currentSalesOrder[0]
            };

        case SELECT_CURRENT_SALES_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentSalesProduct: false,
                    currentSalesOrder: false,
                },
                currentSalesProduct: action.payload
            };

        case SELECT_CURRENT_SALES_ORDER_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentSalesProduct: false,
                    currentSalesOrder: false,
                },
                currentSalesProduct: []
            };

        case SHOW_EDIT_SALES_ORDER:
            return {
                ...state,
                showEditSalesOrder: action.payload,
                currentSalesProduct: [],
                salesProductEditingKey: '',
                isEnabled: {
                    ...state.isEnabled,
                    addSalesProductButton: true,
                },
            };

        case SHOW_ADD_SALES_ORDER:
            return {
                ...state,
                showAddSalesOrder: action.payload,
                currentSalesOrder: {},
                currentSalesProduct: [],
                salesProductEditingKey: '',
                isEnabled: {
                    ...state.isEnabled,
                    addSalesProductButton: true,
                },
            };

        case LOAD_SALES_ORDER_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: true,
                }
            };
        
        case LOAD_SALES_ORDER_LIST_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                },
                salesOrderList: action.payload,
            };

        case LOAD_SALES_ORDER_LIST_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    salesOrderList: false,
                },
                salesOrderList: [],
            };

        default: 
            return state;
    }
}
