export const LOAD_INVENTORY_LIST_REQUEST = 'LOAD_INVENTORY_LIST_REQUEST';
export const LOAD_INVENTORY_LIST_SUCCESS = 'LOAD_INVENTORY_LIST_SUCCESS';
export const LOAD_INVENTORY_LIST_FAILURE = 'LOAD_INVENTORY_LIST_FAILURE';

export const EDIT_INVENTORY_RECORD_REQUEST = 'EDIT_INVENTORY_RECORD_REQUEST';

export const SAVE_INVENTORY_RECORD_REQUEST = 'SAVE_INVENTORY_RECORD_REQUEST';
export const SAVE_INVENTORY_RECORD_SUCCESS = 'SAVE_INVENTORY_RECORD_SUCCESS';
export const SAVE_INVENTORY_RECORD_FAILURE = 'SAVE_INVENTORY_RECORD_FAILURE';

export const DELETE_INVENTORY_RECORD_REQUEST = 'DELETE_INVENTORY_RECORD_REQUEST';
export const DELETE_INVENTORY_RECORD_SUCCESS = 'DELETE_INVENTORY_RECORD_SUCCESS';
export const DELETE_INVENTORY_RECORD_FAILURE = 'DELETE_INVENTORY_RECORD_FAILURE';

export const ADD_TEMP_INVENTORY_RECORD_REQUEST = 'ADD_TEMP_INVENTORY_RECORD_REQUEST';
export const CANCEL_INVENTORY_RECORD_REQUEST = 'CANCEL_INVENTORY_RECORD_REQUEST';
