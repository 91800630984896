//import libraries
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';


const Dashboard = React.lazy( () => import('./pages/Dashboard'));
const Sales = React.lazy( () => import('./pages/Sales'));
const Recipes = React.lazy( () => import ('./pages/Recipes'));
const Delivery = React.lazy( () => import('./pages/Delivery'));
const Products = React.lazy( () => import('./pages/Products'));
const Inventory = React.lazy( () => import('./pages/Inventory'));

// create a component
class routes extends Component {

    render(){
        return (
            <React.Suspense fallback={<span>Loading...</span>} >
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/sales" component={Sales} />
                    <Route exact path="/recipes" component={Recipes} />
                    <Route exact path="/delivery" component={Delivery} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/inventory" component={Inventory} />
                </Switch>
            </React.Suspense>
        );
    }
}


export default routes;

