import React, { Component }  from 'react';
import { Layout, Menu, Icon, Avatar, Row, Col, Badge, Button } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import config from '../config';
import Routes from '../routes';

const { Header, Footer, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;

class SiteLayout extends Component {
    
    render() {

        const style = { background: config.backgroundColor };

        return (
            <Layout style={style}>
                <Sider 
                    style={{
                        ...style, 
                        boxShadow: '1px 1px 5px #888888'
                    }}
                    breakpoint="lg"
                    collapsedWidth="0"
                    //collapsed={this.state.collapsed}
                    //onCollapse={this.onCollapse}
                    theme={config.theme}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems:' center', paddingTop: '15px', paddingBottom: '15px' }}>
                        <span style={{fontSize:'20px'}}><Icon style={{color:config.primaryColor}} type="fire" theme='filled'/> LIBRA</span>
                    </div>
                    <Menu style={style} theme={config.theme} mode="inline" defaultSelectedKeys={['dashboard']}>
                        <Menu.Item key="dashboard">
                            <Link to="/dashboard"><Icon type='dashboard' /><span>Dashboard</span></Link>
                        </Menu.Item>
                        <Menu.Item key="sales">
                            <Link to="/sales"><Icon type='shopping' /><span>Sales</span></Link>
                        </Menu.Item>
                        <Menu.Item key="delivery">
                            <Link to="/delivery"><Icon type='compass' /><span>Delivery</span></Link>
                        </Menu.Item>
                        <Menu.Item key="products">
                            <Link to="/products"><Icon type='shop' /><span>Products</span></Link>
                        </Menu.Item>
                        <Menu.Item key="recipes">
                            <Link to="/recipes"><Icon type='read' /><span>Recipes</span></Link>
                        </Menu.Item>
                        <Menu.Item key="inventory">
                            <Link to="/inventory"><Icon type='barcode' /><span>Inventory</span></Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={style}>
                    <Header style={style}>
                        <Row gutter={10}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={11}>
                            </Col>
                            <Col xs={0} sm={8} md={8} lg={8} xl={9} align='right' >
                                Xan Mathias Huang
                            </Col>
                            <Col xs={8} sm={2} md={2} lg={2} xl={1} align='right'>
                                <Avatar 
                                    icon="user" 
                                    shape='circle'
                                    size='large'
                                    style={{ border: '5px', borderColor: 'white' }}
                                    src={`https://lh3.googleusercontent.com/a-/AAuE7mDXEco7JjAUTg-5sCznMfDATB13nuYRwWdPawX2Bw=s96-c`}
                                />
                            </Col>
                            <Col span={1}
                                align='center'
                            >
                                <Badge dot={true}>
                                <span style={{fontSize:'20px'}}>                      
                                    <Icon type='bell' />
                                </span>
                                </Badge>
                            </Col>
                            <Col span={2} align='center'>
                                <Button type='primary' >Logout</Button>
                            </Col>
                        </Row>
                    </Header>
                    <Content style={style}>
                        <div style={{  
                            padding: 24, minHeight: 280, 
                            background: config.secondaryBackgroundColor, 
                            borderTop: '3px solid #ffc0cb' 
                        }}> 
                            <Routes />
                        </div>
                    </Content>
                    <Footer style={{
                        background: config.secondaryBackgroundColor, 
                        textAlign: 'center'
                        }}>
                        <Icon style={{color:config.primaryColor}} type="fire" theme='filled'/> LIBRA - Limitless Bakery Analytics © {new Date().getFullYear()} 
                    </Footer>
                </Layout>
            </Layout>
        )
    }
}

export default withRouter(SiteLayout)