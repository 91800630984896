import React from 'react';
import { notification, Icon } from 'antd';

import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    LOAD_RECIPE_LIST_REQUEST,
    LOAD_RECIPE_LIST_SUCCESS,
    LOAD_RECIPE_LIST_FAILURE,
    SELECT_CURRENT_RECIPE_REQUEST,
    SELECT_CURRENT_RECIPE_SUCCESS,
    SELECT_CURRENT_RECIPE_FAILURE,
    LOAD_INVENTORY_NAMES_REQUEST,
    LOAD_INVENTORY_NAMES_SUCCESS,
    LOAD_INVENTORY_NAMES_FAILURE,
    EDIT_CURRENT_RECIPE_REQUEST,
    EDIT_CURRENT_RECIPE_SUCCESS,
    EDIT_CURRENT_RECIPE_FAILURE,
    ADD_CURRENT_RECIPE_REQUEST,
    ADD_CURRENT_RECIPE_SUCCESS,
    ADD_CURRENT_RECIPE_FAILURE,
    DELETE_RECIPE_REQUEST,
    DELETE_RECIPE_SUCCESS,
    DELETE_RECIPE_FAILURE,
} from './types';
import axios from 'axios';

//var id = 100;
//const newKey = 99999999999999;

function* loadRecipeList() {
    try {
        
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/recipe?token=${token}`
        )
        yield put({
            type: LOAD_RECIPE_LIST_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_RECIPE_LIST_FAILURE });
    }
}

function* selectCurrentRecipe(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/recipeInventory?token=${token}&id=${action.payload}`
        )
        
        yield put({
            type: SELECT_CURRENT_RECIPE_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: SELECT_CURRENT_RECIPE_FAILURE });
    }
}

function* loadInventoryNames(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/inventoryList?token=${token}`
        )
        
        yield put({
            type: LOAD_INVENTORY_NAMES_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_INVENTORY_NAMES_FAILURE });
    }
}

function* editCurrentRecipe(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.API_URL}/recipe`,
            {
                token,
                ...action.payload,
            }
        )
        
        yield put({
            type: EDIT_CURRENT_RECIPE_SUCCESS,
            payload: ret.data[0]
        });
    } catch (err) {
        console.error(err);
        yield put({ type: EDIT_CURRENT_RECIPE_FAILURE });
    }
}

function* addCurrentRecipe(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.post,
            `${config.API_URL}/recipe`,
            {
                token,
                ...action.payload,
            }
        )
        
        yield put({
            type: ADD_CURRENT_RECIPE_SUCCESS,
            payload: ret.data[0]
        });
    } catch (err) {
        console.error(err);
        yield put({ type: ADD_CURRENT_RECIPE_FAILURE });
    }
}

function* deleteRecipe(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.delete,
            `${config.API_URL}/recipe?token=${token}&id=${action.payload}`
        )

        if (ret.data === 'OK')
        {
            yield put({
                type: DELETE_RECIPE_SUCCESS,
                payload: action.payload
            });
        } else {
            notification.open({
                message: 'Notifications',
                description: ret.data,
                icon: <Icon type="info-circle" style={{ color: config.primaryColor }} />
            })
            yield put({ 
                type: DELETE_RECIPE_FAILURE,
                payload: ret.data
            });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: DELETE_RECIPE_FAILURE });
    }
}

export default function* () {
    yield takeLatest(LOAD_RECIPE_LIST_REQUEST, loadRecipeList);
    yield takeEvery(SELECT_CURRENT_RECIPE_REQUEST, selectCurrentRecipe);
    yield takeLatest(LOAD_INVENTORY_NAMES_REQUEST, loadInventoryNames);
    yield takeEvery(EDIT_CURRENT_RECIPE_REQUEST, editCurrentRecipe);
    yield takeEvery(ADD_CURRENT_RECIPE_REQUEST, addCurrentRecipe);
    yield takeEvery(DELETE_RECIPE_REQUEST, deleteRecipe);
};
