import React from 'react';
import { connect } from 'react-redux';
import { 
    addTempInventoryRecord,
} from './services/Inventory/actions';
import { GlobalHotKeys, getApplicationKeyMap} from "react-hotkeys";


class HotKeysHandlers extends React.Component {
    render() {
        const keyMap = {
            ADD_TEMP_INVENTORY_RECORD: "shift+command+i",
        };

        const handlers = {
            ADD_TEMP_INVENTORY_RECORD : e => { console.log('ADD_TEMP_INVENTORY_RECORD');this.props.addTempInventoryRecord(); }, 
        };

        return (
            <div>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = {
    addTempInventoryRecord, 
};

export default connect(mapStateToProps, mapDispatchToProps)(HotKeysHandlers);


