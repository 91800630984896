import {
    LOAD_DELIVERY_DETAILS_REQUEST,
    LOAD_DELIVERY_DETAILS_SUCCESS,
    LOAD_DELIVERY_DETAILS_FAILURE,
    UPDATE_DELIVERY_STATUS_REQUEST,
    UPDATE_DELIVERY_STATUS_SUCCESS,
    UPDATE_DELIVERY_STATUS_FAILURE,
    UPDATE_PAYMENT_STATUS_REQUEST,
    UPDATE_PAYMENT_STATUS_SUCCESS,
    UPDATE_PAYMENT_STATUS_FAILURE
} from './types';

const DEFAULT_STATE = {
    isLoading: {
        deliveryDetails: false,
        updateDeliveryStatus: false,
    },
    isEnabled: {
        //addButton: true,
    },
    deliveryDetails : []
};

let newDeliveryDetails;

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case LOAD_DELIVERY_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    deliveryDetails: true,
                }
            };
        
        case LOAD_DELIVERY_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    deliveryDetails: false,
                },
                deliveryDetails: action.payload,
            };

        case LOAD_DELIVERY_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    deliveryDetails: false,
                },
                deliveryDetails: [],
            };

        case UPDATE_DELIVERY_STATUS_REQUEST:
            newDeliveryDetails = state.deliveryDetails;
            for (var i=0;i<newDeliveryDetails.length;i++) {
                if (newDeliveryDetails[i].id === action.id) {
                    newDeliveryDetails[i].delivered = action.delivered;
                }
            }
            return {
                ...state,
                deliveryDetails: newDeliveryDetails
            };
        
        case UPDATE_DELIVERY_STATUS_SUCCESS:
            return state;

        case UPDATE_DELIVERY_STATUS_FAILURE:
            newDeliveryDetails = state.deliveryDetails;
            for (var i=0;i<newDeliveryDetails.length;i++) {
                if (newDeliveryDetails[i].id === action.payload.id) {
                    newDeliveryDetails[i].delivered = action.payload.delivered === 'Yes' ? 'No' : 'Yes';
                }
            }
            return {
                ...state,
                deliveryDetails: newDeliveryDetails
            };

        case UPDATE_PAYMENT_STATUS_REQUEST:
                newDeliveryDetails = state.deliveryDetails;
                for (var i=0;i<newDeliveryDetails.length;i++) {
                    if (newDeliveryDetails[i].id === action.id) {
                        newDeliveryDetails[i].payment_received = action.payment_received;
                    }
                }
                return {
                    ...state,
                    deliveryDetails: newDeliveryDetails
                };
            
        case UPDATE_PAYMENT_STATUS_SUCCESS:
            return state;

        case UPDATE_PAYMENT_STATUS_FAILURE:
            newDeliveryDetails = state.deliveryDetails;
            for (var i=0;i<newDeliveryDetails.length;i++) {
                if (newDeliveryDetails[i].id === action.payload.id) {
                    newDeliveryDetails[i].payment_received = action.payload.payment_received === 'Yes' ? 'No' : 'Yes';
                }
            }
            return {
                ...state,
                deliveryDetails: newDeliveryDetails
            };


        default: return state;
    }
}
