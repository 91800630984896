export default {
    primaryColor : '#FA255E',
    secondaryColor: '#636363',
    //textColor: '#FFFFFF',
    textColor:'#000000',
    //backgroundColor: '#000000',
    backgroundColor:'#FFFFFF',
    theme: 'light',
    //secondaryBackgroundColor: '#0a0a0a'//'#141414'
    secondaryBackgroundColor: '#FFFFFF',
    
    
    API_URL: process.env.NODE_ENV === 'production' ? 'https://us-central1-libra-api.cloudfunctions.net' : 'http://localhost:5001/libra-api/us-central1'
};