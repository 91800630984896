import {
    LOAD_RECIPE_LIST_REQUEST,
    LOAD_RECIPE_LIST_SUCCESS,
    LOAD_RECIPE_LIST_FAILURE,
    SHOW_EDIT_RECIPE,
    SHOW_ADD_RECIPE,
    SELECT_CURRENT_RECIPE_REQUEST,
    SELECT_CURRENT_RECIPE_SUCCESS,
    SELECT_CURRENT_RECIPE_FAILURE,
    CANCEL_RECIPE_INVENTORY_RECORD,
    EDIT_RECIPE_INVENTORY_RECORD,
    DELETE_RECIPE_INVENTORY_RECORD,
    SAVE_RECIPE_INVENTORY_RECORD,
    ADD_RECIPE_INVENTORY_RECORD,
    LOAD_INVENTORY_NAMES_REQUEST,
    LOAD_INVENTORY_NAMES_SUCCESS,
    LOAD_INVENTORY_NAMES_FAILURE,
    EDIT_CURRENT_RECIPE_REQUEST,
    EDIT_CURRENT_RECIPE_SUCCESS,
    EDIT_CURRENT_RECIPE_FAILURE,
    ADD_CURRENT_RECIPE_REQUEST,
    ADD_CURRENT_RECIPE_SUCCESS,
    ADD_CURRENT_RECIPE_FAILURE,
    DELETE_RECIPE_REQUEST,
    DELETE_RECIPE_SUCCESS,
    DELETE_RECIPE_FAILURE,
    SHOW_VIEW_RECIPE,
} from './types';

const DEFAULT_STATE = {
    isLoading: {
        recipeList: false,
        currentRecipeInventory: false,
        currentRecipe: false,
    },
    isEnabled: {
        addButton: true,
        addRecipeInventoryButton: true,
    },
    inventoryList : [],
    showEditRecipe: false,
    showAddRecipe: false,
    showViewRecipe: false,
    currentRecipe: {},
    currentRecipeInventory: [],
    recipeInventoryEditingKey: '',
    recipeList: [],
};

var newKey = 0;

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case DELETE_RECIPE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: true,
                }
            };

        case DELETE_RECIPE_SUCCESS:
            var newRecipeList = state.recipeList.filter( o => o.key != action.payload );
            newRecipeList.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                },
                recipeList: newRecipeList
            };

        case DELETE_RECIPE_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                }
            };



        case ADD_CURRENT_RECIPE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: true,
                }
            };

        case ADD_CURRENT_RECIPE_SUCCESS:
            var newRecipeList = state.recipeList;
            newRecipeList.push ( action.payload );
            newRecipeList.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                },
                recipeList: newRecipeList
            };

        case ADD_CURRENT_RECIPE_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                }
            };

        case EDIT_CURRENT_RECIPE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: true,
                }
            };

        case EDIT_CURRENT_RECIPE_SUCCESS:
            var newRecipeList = state.recipeList.filter( o => o.key != action.payload.key );
            newRecipeList.push ( action.payload );
            newRecipeList.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                },
                recipeList: newRecipeList
            };

        case EDIT_CURRENT_RECIPE_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                }
            };

        case LOAD_INVENTORY_NAMES_REQUEST:
            return state;

        case LOAD_INVENTORY_NAMES_SUCCESS:
            return {
                ...state,
                inventoryList: action.payload
            };
        
        case LOAD_INVENTORY_NAMES_FAILURE:
            return state;

        case ADD_RECIPE_INVENTORY_RECORD:
            var newCurrentRecipeInventory = state.currentRecipeInventory;
            ++newKey;
            newCurrentRecipeInventory.push({
                key: 'N'+newKey, 
                id: 'N'+newKey,
                item: '',
                quantity: '',
            });
            
            newCurrentRecipeInventory.sort( (a,b) => a.description < b.description ? -1 : 1 );
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addRecipeInventoryButton: false,
                },
                recipeInventoryEditingKey: 'N'+newKey,
                currentRecipeInventory: newCurrentRecipeInventory,
            }
            
        case SAVE_RECIPE_INVENTORY_RECORD:
            //var newCurrentRecipeInventory = state.currentRecipeInventory.filter( o => o.key != 'N'+newKey && o.key != action.payload.key );
            var newCurrentRecipeInventory = state.currentRecipeInventory.filter( o => o.key != action.payload.key );
            newCurrentRecipeInventory.push( action.payload );
            newCurrentRecipeInventory.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addRecipeInventoryButton: true
                },
                recipeInventoryEditingKey: '',
                currentRecipeInventory: newCurrentRecipeInventory,
            };
        
        case DELETE_RECIPE_INVENTORY_RECORD:
            var newCurrentRecipeInventory = state.currentRecipeInventory.filter( o => o.key != action.payload );
            
            return {
                ...state,
                currentRecipeInventory: newCurrentRecipeInventory,
            };

        case EDIT_RECIPE_INVENTORY_RECORD:
            return {
                ...state,
                recipeInventoryEditingKey: action.payload,
                isEnabled: {
                    ...state.isEnabled,
                    addRecipeInventoryButton: false,
                }
            };
        
        case CANCEL_RECIPE_INVENTORY_RECORD:
            var newCurrentRecipeInventory = state.currentRecipeInventory.filter( o => o.key != 'N'+newKey );
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addRecipeInventoryButton: true
                },
                recipeInventoryEditingKey: '',
                currentRecipeInventory: newCurrentRecipeInventory,
            };

        case SELECT_CURRENT_RECIPE_REQUEST:
            var currentRecipe = state.recipeList.filter( o => o.key === action.payload );
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentRecipeInventory: true,
                    currentRecipe: true,
                },
                currentRecipe: currentRecipe[0]
            };

        case SELECT_CURRENT_RECIPE_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentRecipeInventory: false,
                    currentRecipe: false,
                },
                currentRecipeInventory: action.payload
            };

        case SELECT_CURRENT_RECIPE_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentRecipeInventory: false,
                    currentRecipe: false,
                },
                currentRecipeInventory: []
            };

        case SHOW_EDIT_RECIPE:
            return {
                ...state,
                showEditRecipe: action.payload,
                currentRecipeInventory: [],
                recipeInventoryEditingKey: '',
                isEnabled: {
                    ...state.isEnabled,
                    addRecipeInventoryButton: true,
                },
            };

        case SHOW_VIEW_RECIPE:
            return {
                ...state,
                showViewRecipe: action.payload,
                currentRecipeInventory: [],
            };    

        case SHOW_ADD_RECIPE:
            return {
                ...state,
                showAddRecipe: action.payload,
                currentRecipe: {},
                currentRecipeInventory: [],
                recipeInventoryEditingKey: '',
                isEnabled: {
                    ...state.isEnabled,
                    addRecipeInventoryButton: true,
                },
            };

        case LOAD_RECIPE_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: true,
                }
            };
        
        case LOAD_RECIPE_LIST_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                },
                recipeList: action.payload,
            };

        case LOAD_RECIPE_LIST_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    recipeList: false,
                },
                recipeList: [],
            };

        default: 
            return state;
    }
}
