import {
    LOAD_INVENTORY_LIST_REQUEST,
    LOAD_INVENTORY_LIST_SUCCESS,
    LOAD_INVENTORY_LIST_FAILURE,
    EDIT_INVENTORY_RECORD_REQUEST,
    SAVE_INVENTORY_RECORD_REQUEST,
    SAVE_INVENTORY_RECORD_SUCCESS,
    SAVE_INVENTORY_RECORD_FAILURE,
    DELETE_INVENTORY_RECORD_REQUEST,
    DELETE_INVENTORY_RECORD_SUCCESS,
    DELETE_INVENTORY_RECORD_FAILURE,
    ADD_TEMP_INVENTORY_RECORD_REQUEST,
    CANCEL_INVENTORY_RECORD_REQUEST,
} from './types';

export const loadInventoryList = () => ({
    type: LOAD_INVENTORY_LIST_REQUEST,
})

export const editInventoryRecord = (key) => ({
    type: EDIT_INVENTORY_RECORD_REQUEST,
    payload: key,
})

export const saveInventoryRecord = (row) => ({
    type: SAVE_INVENTORY_RECORD_REQUEST,
    payload: row,
})

export const deleteInventoryRecord = (key) => ({
    type: DELETE_INVENTORY_RECORD_REQUEST,
    payload: key,
})

export const addTempInventoryRecord = () => ({
    type: ADD_TEMP_INVENTORY_RECORD_REQUEST,
})

export const cancelInventoryRecord = () => ({
    type: CANCEL_INVENTORY_RECORD_REQUEST,
})

