import React from 'react';
import { notification, Icon } from 'antd';
import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    LOAD_PRODUCT_LIST_REQUEST,
    LOAD_PRODUCT_LIST_SUCCESS,
    LOAD_PRODUCT_LIST_FAILURE,
    SELECT_CURRENT_PRODUCT_REQUEST,
    SELECT_CURRENT_PRODUCT_SUCCESS,
    SELECT_CURRENT_PRODUCT_FAILURE,
    LOAD_RECIPE_NAMES_REQUEST,
    LOAD_RECIPE_NAMES_SUCCESS,
    LOAD_RECIPE_NAMES_FAILURE,
    EDIT_CURRENT_PRODUCT_REQUEST,
    EDIT_CURRENT_PRODUCT_SUCCESS,
    EDIT_CURRENT_PRODUCT_FAILURE,
    ADD_CURRENT_PRODUCT_REQUEST,
    ADD_CURRENT_PRODUCT_SUCCESS,
    ADD_CURRENT_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
} from './types';
import axios from 'axios';

//var id = 100;
//const newKey = 99999999999999;

function* loadProductList() {
    try {
        
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/product?token=${token}`
        )
        yield put({
            type: LOAD_PRODUCT_LIST_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_PRODUCT_LIST_FAILURE });
    }
}

function* selectCurrentProduct(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/productRecipe?token=${token}&id=${action.payload}`
        )
        
        yield put({
            type: SELECT_CURRENT_PRODUCT_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: SELECT_CURRENT_PRODUCT_FAILURE });
    }
}

function* loadRecipeNames(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/recipeList?token=${token}`
        )
        
        yield put({
            type: LOAD_RECIPE_NAMES_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_RECIPE_NAMES_FAILURE });
    }
}

function* editCurrentProduct(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.API_URL}/product`,
            {
                token,
                ...action.payload,
            }
        )
        
        yield put({
            type: EDIT_CURRENT_PRODUCT_SUCCESS,
            payload: ret.data[0]
        });
    } catch (err) {
        console.error(err);
        yield put({ type: EDIT_CURRENT_PRODUCT_FAILURE });
    }
}

function* addCurrentProduct(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.post,
            `${config.API_URL}/product`,
            {
                token,
                ...action.payload,
            }
        )
        
        yield put({
            type: ADD_CURRENT_PRODUCT_SUCCESS,
            payload: ret.data[0]
        });
    } catch (err) {
        console.error(err);
        yield put({ type: ADD_CURRENT_PRODUCT_FAILURE });
    }
}

function* deleteProduct(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.delete,
            `${config.API_URL}/product?token=${token}&id=${action.payload}`
        )

        if (ret.data === 'OK')
        {
            yield put({
                type: DELETE_PRODUCT_SUCCESS,
                payload: action.payload
            });
        } else {
            notification.open({
                message: 'Notifications',
                description: ret.data,
                icon: <Icon type="info-circle" style={{ color: config.primaryColor }} />
            })
            yield put({ 
                type: DELETE_PRODUCT_FAILURE,
                payload: ret.data
            });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: DELETE_PRODUCT_FAILURE });
    }
}

export default function* () {
    yield takeLatest(LOAD_PRODUCT_LIST_REQUEST, loadProductList);
    yield takeEvery(SELECT_CURRENT_PRODUCT_REQUEST, selectCurrentProduct);
    yield takeLatest(LOAD_RECIPE_NAMES_REQUEST, loadRecipeNames);
    yield takeEvery(EDIT_CURRENT_PRODUCT_REQUEST, editCurrentProduct);
    yield takeEvery(ADD_CURRENT_PRODUCT_REQUEST, addCurrentProduct);
    yield takeEvery(DELETE_PRODUCT_REQUEST, deleteProduct);
};
