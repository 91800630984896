import Inventory from './Inventory/sagas';
import Delivery from './Delivery/sagas';
import Sales from './Sales/sagas';
import Product from './Product/sagas';
import Recipe from './Recipe/sagas';

export default [
    Inventory,
    Delivery,
    Sales,
    Product,
    Recipe
];
