import {
    LOAD_INVENTORY_LIST_REQUEST,
    LOAD_INVENTORY_LIST_SUCCESS,
    LOAD_INVENTORY_LIST_FAILURE,
    EDIT_INVENTORY_RECORD_REQUEST,
    SAVE_INVENTORY_RECORD_REQUEST,
    SAVE_INVENTORY_RECORD_SUCCESS,
    SAVE_INVENTORY_RECORD_FAILURE,
    DELETE_INVENTORY_RECORD_REQUEST,
    DELETE_INVENTORY_RECORD_SUCCESS,
    DELETE_INVENTORY_RECORD_FAILURE,
    ADD_TEMP_INVENTORY_RECORD_REQUEST,
    CANCEL_INVENTORY_RECORD_REQUEST,
    
} from './types';

const DEFAULT_STATE = {
    isLoading: {
        inventoryList: false,
    },
    isEnabled: {
        addButton: true,
    },
    editingKey: '',
    inventoryList : []
};

const newKey = 99999999999999;

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case LOAD_INVENTORY_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: true,
                }
            };
        
        case LOAD_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: false,
                },
                inventoryList: action.payload,
            };

        case LOAD_INVENTORY_LIST_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: false,
                },
                inventoryList: [],
            };

        case EDIT_INVENTORY_RECORD_REQUEST:
            return {
                ...state,
                editingKey: action.payload,
                isEnabled: {
                    ...state.isEnabled,
                    addButton: false,
                }
            };

        case SAVE_INVENTORY_RECORD_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: true,
                }
            };
            
        case SAVE_INVENTORY_RECORD_SUCCESS:
            var newInventoryList = state.inventoryList.filter( o => o.key != newKey && o.key != action.payload.key );
            newInventoryList.push( action.payload );
            newInventoryList.sort( (a,b) => (b.key - a.key));
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: false,
                },
                isEnabled: {
                    ...state.isEnabled,
                    addButton: true
                },
                editingKey: '',
                inventoryList: newInventoryList,
            };

        case SAVE_INVENTORY_RECORD_FAILURE:
            return {
                ...state, //do nothing
            };


        case DELETE_INVENTORY_RECORD_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: true,
                }
            };
            
        case DELETE_INVENTORY_RECORD_SUCCESS:
            var newInventoryList = state.inventoryList.filter( o => o.key != action.payload );
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: false,
                },
                isEnabled: {
                    ...state.isEnabled,
                    addButton: true
                },
                editingKey: '',
                inventoryList: newInventoryList,
            };

        case DELETE_INVENTORY_RECORD_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    inventoryList: false,
                }
            };

        case ADD_TEMP_INVENTORY_RECORD_REQUEST:
            if ( state.editingKey === newKey ) {
                return state;
            } else {
                var newInventoryList = state.inventoryList;
                newInventoryList.push({
                    key: newKey, 
                    date: new Date().toLocaleString(), 
                    item:'',
                    quantity:'',
                    unit:'g',
                    cost:''
                });
                newInventoryList.sort( (a,b) => (b.key - a.key));
                return {
                    ...state,
                    isEnabled: {
                        ...state.isEnabled,
                        addButton: false,
                    },
                    editingKey: newKey,
                    inventoryList: newInventoryList,
                }
            }
        
        case CANCEL_INVENTORY_RECORD_REQUEST:
            var newInventoryList = state.inventoryList.filter( o => o.key != newKey );
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addButton: true
                },
                editingKey: '',
                inventoryList: newInventoryList,
            };



        default: return state;
    }
}
