export const LOAD_PRODUCT_LIST_REQUEST = 'LOAD_PRODUCT_LIST_REQUEST';
export const LOAD_PRODUCT_LIST_SUCCESS = 'LOAD_PRODUCT_LIST_SUCCESS';
export const LOAD_PRODUCT_LIST_FAILURE = 'LOAD_PRODUCT_LIST_FAILURE';

export const SHOW_EDIT_PRODUCT = 'SHOW_EDIT_PRODUCT';
export const SHOW_ADD_PRODUCT = 'SHOW_ADD_PRODUCT';

export const SELECT_CURRENT_PRODUCT_REQUEST = 'SELECT_CURRENT_PRODUCT_REQUEST';
export const SELECT_CURRENT_PRODUCT_SUCCESS = 'SELECT_CURRENT_PRODUCT_SUCCESS';
export const SELECT_CURRENT_PRODUCT_FAILURE = 'SELECT_CURRENT_PRODUCT_FAILURE';

export const EDIT_CURRENT_PRODUCT_REQUEST = 'EDIT_CURRENT_PRODUCT_REQUEST';
export const EDIT_CURRENT_PRODUCT_SUCCESS = 'EDIT_CURRENT_PRODUCT_SUCCESS';
export const EDIT_CURRENT_PRODUCT_FAILURE = 'EDIT_CURRENT_PRODUCT_FAILURE';

export const ADD_CURRENT_PRODUCT_REQUEST = 'ADD_CURRENT_PRODUCT_REQUEST';
export const ADD_CURRENT_PRODUCT_SUCCESS = 'ADD_CURRENT_PRODUCT_SUCCESS';
export const ADD_CURRENT_PRODUCT_FAILURE = 'ADD_CURRENT_PRODUCT_FAILURE';

export const CANCEL_PRODUCT_RECIPE_RECORD = 'CANCEL_PRODUCT_RECIPE_RECORD';
export const EDIT_PRODUCT_RECIPE_RECORD = 'EDIT_PRODUCT_RECIPE_RECORD';

export const DELETE_PRODUCT_RECIPE_RECORD = 'DELETE_PRODUCT_RECIPE_RECORD';
export const SAVE_PRODUCT_RECIPE_RECORD = 'SAVE_PRODUCT_RECIPE_RECORD';
export const ADD_PRODUCT_RECIPE_RECORD = 'ADD_PRODUCT_RECIPE_RECORD';

export const LOAD_RECIPE_NAMES_REQUEST = 'LOAD_RECIPE_NAMES_REQUEST';
export const LOAD_RECIPE_NAMES_SUCCESS = 'LOAD_RECIPE_NAMES_SUCCESS';
export const LOAD_RECIPE_NAMES_FAILURE = 'LOAD_RECIPE_NAMES_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
