import React from 'react';
import SiteLayout from '../pages/SiteLayout';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../services/store';
import HotKeysHandlers from '../HotKeysHandlers';

function App() {

  const keyMap = {
    ADD_TEMP_INVENTORY_RECORD: "command+up",
  };

  const handlers = {
    ADD_TEMP_INVENTORY_RECORD : e => { console.log('pressed');this.prop.addTempInventoryRecord(); } 
  };

  return (
    
    
    <Provider store={store()}>
        <HotKeysHandlers />
        <BrowserRouter>
          <SiteLayout />
        </BrowserRouter>
    </Provider>
    

    
    
  );
}



export default App;
