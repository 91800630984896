export const LOAD_RECIPE_LIST_REQUEST = 'LOAD_RECIPE_LIST_REQUEST';
export const LOAD_RECIPE_LIST_SUCCESS = 'LOAD_RECIPE_LIST_SUCCESS';
export const LOAD_RECIPE_LIST_FAILURE = 'LOAD_RECIPE_LIST_FAILURE';

export const SHOW_EDIT_RECIPE = 'SHOW_EDIT_RECIPE';
export const SHOW_ADD_RECIPE = 'SHOW_ADD_RECIPE';
export const SHOW_VIEW_RECIPE = 'SHOW_VIEW_RECIPE';

export const SELECT_CURRENT_RECIPE_REQUEST = 'SELECT_CURRENT_RECIPE_REQUEST';
export const SELECT_CURRENT_RECIPE_SUCCESS = 'SELECT_CURRENT_RECIPE_SUCCESS';
export const SELECT_CURRENT_RECIPE_FAILURE = 'SELECT_CURRENT_RECIPE_FAILURE';

export const EDIT_CURRENT_RECIPE_REQUEST = 'EDIT_CURRENT_RECIPE_REQUEST';
export const EDIT_CURRENT_RECIPE_SUCCESS = 'EDIT_CURRENT_RECIPE_SUCCESS';
export const EDIT_CURRENT_RECIPE_FAILURE = 'EDIT_CURRENT_RECIPE_FAILURE';

export const ADD_CURRENT_RECIPE_REQUEST = 'ADD_CURRENT_RECIPE_REQUEST';
export const ADD_CURRENT_RECIPE_SUCCESS = 'ADD_CURRENT_RECIPE_SUCCESS';
export const ADD_CURRENT_RECIPE_FAILURE = 'ADD_CURRENT_RECIPE_FAILURE';

export const CANCEL_RECIPE_INVENTORY_RECORD = 'CANCEL_RECIPE_INVENTORY_RECORD';
export const EDIT_RECIPE_INVENTORY_RECORD = 'EDIT_RECIPE_INVENTORY_RECORD';

export const DELETE_RECIPE_INVENTORY_RECORD = 'DELETE_RECIPE_INVENTORY_RECORD';
export const SAVE_RECIPE_INVENTORY_RECORD = 'SAVE_RECIPE_INVENTORY_RECORD';
export const ADD_RECIPE_INVENTORY_RECORD = 'ADD_RECIPE_INVENTORY_RECORD';

export const LOAD_INVENTORY_NAMES_REQUEST = 'LOAD_INVENTORY_NAMES_REQUEST';
export const LOAD_INVENTORY_NAMES_SUCCESS = 'LOAD_INVENTORY_NAMES_SUCCESS';
export const LOAD_INVENTORY_NAMES_FAILURE = 'LOAD_INVENTORY_NAMES_FAILURE';

export const DELETE_RECIPE_REQUEST = 'DELETE_RECIPE_REQUEST';
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS';
export const DELETE_RECIPE_FAILURE = 'DELETE_RECIPE_FAILURE';
