import {
    LOAD_PRODUCT_LIST_REQUEST,
    LOAD_PRODUCT_LIST_SUCCESS,
    LOAD_PRODUCT_LIST_FAILURE,
    SHOW_EDIT_PRODUCT,
    SHOW_ADD_PRODUCT,
    SELECT_CURRENT_PRODUCT_REQUEST,
    SELECT_CURRENT_PRODUCT_SUCCESS,
    SELECT_CURRENT_PRODUCT_FAILURE,
    CANCEL_PRODUCT_RECIPE_RECORD,
    EDIT_PRODUCT_RECIPE_RECORD,
    DELETE_PRODUCT_RECIPE_RECORD,
    SAVE_PRODUCT_RECIPE_RECORD,
    ADD_PRODUCT_RECIPE_RECORD,
    LOAD_RECIPE_NAMES_REQUEST,
    LOAD_RECIPE_NAMES_SUCCESS,
    LOAD_RECIPE_NAMES_FAILURE,
    EDIT_CURRENT_PRODUCT_REQUEST,
    EDIT_CURRENT_PRODUCT_SUCCESS,
    EDIT_CURRENT_PRODUCT_FAILURE,
    ADD_CURRENT_PRODUCT_REQUEST,
    ADD_CURRENT_PRODUCT_SUCCESS,
    ADD_CURRENT_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
} from './types';

import moment from 'moment';

const DEFAULT_STATE = {
    isLoading: {
        productList: false,
        currentProductRecipe: false,
        currentProduct: false,
    },
    isEnabled: {
        addButton: true,
        addProductRecipeButton: true,
    },
    recipeList : [],
    showEditProduct: false,
    showAddProduct: false,
    currentProduct: {},
    currentProductRecipe: [],
    productRecipeEditingKey: '',
    productList: [],
};

var newKey = 0;

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: true,
                }
            };

        case DELETE_PRODUCT_SUCCESS:
            var newProductList = state.productList.filter( o => o.key != action.payload );
            newProductList.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                },
                productList: newProductList
            };

        case DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                }
            };



        case ADD_CURRENT_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: true,
                }
            };

        case ADD_CURRENT_PRODUCT_SUCCESS:
            var newProductList = state.productList;
            newProductList.push ( action.payload );
            newProductList.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                },
                productList: newProductList
            };

        case ADD_CURRENT_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                }
            };

        case EDIT_CURRENT_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: true,
                }
            };

        case EDIT_CURRENT_PRODUCT_SUCCESS:
            var newProductList = state.productList.filter( o => o.key != action.payload.key );
            newProductList.push ( action.payload );
            newProductList.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                },
                productList: newProductList
            };

        case EDIT_CURRENT_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                }
            };

        case LOAD_RECIPE_NAMES_REQUEST:
            return state;

        case LOAD_RECIPE_NAMES_SUCCESS:
            return {
                ...state,
                recipeList: action.payload
            };
        
        case LOAD_RECIPE_NAMES_FAILURE:
            return state;

        case ADD_PRODUCT_RECIPE_RECORD:
            var newCurrentProductRecipe = state.currentProductRecipe;
            ++newKey;
            newCurrentProductRecipe.push({
                key: 'N'+newKey, 
                id: 'N'+newKey,
                description: '',
                quantity: '0',
            });
            
            newCurrentProductRecipe.sort( (a,b) => a.description < b.description ? -1 : 1 );
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addProductRecipeButton: false,
                },
                productRecipeEditingKey: 'N'+newKey,
                currentProductRecipe: newCurrentProductRecipe,
            }
            
        case SAVE_PRODUCT_RECIPE_RECORD:
            //var newCurrentProductRecipe = state.currentProductRecipe.filter( o => o.key != 'N'+newKey && o.key != action.payload.key );
            var newCurrentProductRecipe = state.currentProductRecipe.filter( o => o.key != action.payload.key );
            newCurrentProductRecipe.push( action.payload );
            newCurrentProductRecipe.sort( (a,b) => a.description < b.description ? -1 : 1 );

            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addProductRecipeButton: true
                },
                productRecipeEditingKey: '',
                currentProductRecipe: newCurrentProductRecipe,
            };
        
        case DELETE_PRODUCT_RECIPE_RECORD:
            var newCurrentProductRecipe = state.currentProductRecipe.filter( o => o.key != action.payload );
            
            return {
                ...state,
                currentProductRecipe: newCurrentProductRecipe,
            };

        case EDIT_PRODUCT_RECIPE_RECORD:
            return {
                ...state,
                productRecipeEditingKey: action.payload,
                isEnabled: {
                    ...state.isEnabled,
                    addProductRecipeButton: false,
                }
            };
        
        case CANCEL_PRODUCT_RECIPE_RECORD:
            var newCurrentProductRecipe = state.currentProductRecipe.filter( o => o.key != 'N'+newKey );
            return {
                ...state,
                isEnabled: {
                    ...state.isEnabled,
                    addProductRecipeButton: true
                },
                productRecipeEditingKey: '',
                currentProductRecipe: newCurrentProductRecipe,
            };

        case SELECT_CURRENT_PRODUCT_REQUEST:
            var currentProduct = state.productList.filter( o => o.key === action.payload );
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentProductRecipe: true,
                    currentProduct: true,
                },
                currentProduct: currentProduct[0]
            };

        case SELECT_CURRENT_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentProductRecipe: false,
                    currentProduct: false,
                },
                currentProductRecipe: action.payload
            };

        case SELECT_CURRENT_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    currentProductRecipe: false,
                    currentProduct: false,
                },
                currentProductRecipe: []
            };

        case SHOW_EDIT_PRODUCT:
            return {
                ...state,
                showEditProduct: action.payload,
                currentProductRecipe: [],
                productRecipeEditingKey: '',
                isEnabled: {
                    ...state.isEnabled,
                    addProductRecipeButton: true,
                },
            };

        case SHOW_ADD_PRODUCT:
            return {
                ...state,
                showAddProduct: action.payload,
                currentProduct: {},
                currentProductRecipe: [],
                productRecipeEditingKey: '',
                isEnabled: {
                    ...state.isEnabled,
                    addProductRecipeButton: true,
                },
            };

        case LOAD_PRODUCT_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: true,
                }
            };
        
        case LOAD_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                },
                productList: action.payload,
            };

        case LOAD_PRODUCT_LIST_FAILURE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    productList: false,
                },
                productList: [],
            };

        default: 
            return state;
    }
}
