import React from 'react';
import { notification, Icon } from 'antd';
import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    LOAD_INVENTORY_LIST_REQUEST,
    LOAD_INVENTORY_LIST_SUCCESS,
    LOAD_INVENTORY_LIST_FAILURE,
    EDIT_INVENTORY_RECORD_REQUEST,
    SAVE_INVENTORY_RECORD_REQUEST,
    SAVE_INVENTORY_RECORD_SUCCESS,
    SAVE_INVENTORY_RECORD_FAILURE,
    DELETE_INVENTORY_RECORD_REQUEST,
    DELETE_INVENTORY_RECORD_SUCCESS,
    DELETE_INVENTORY_RECORD_FAILURE,
    ADD_TEMP_INVENTORY_RECORD_REQUEST,
    CANCEL_INVENTORY_RECORD_REQUEST,
} from './types';
import axios from 'axios';

var id = 100;
const newKey = 99999999999999;

function* loadInventoryList() {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/inventory?token=${token}`
        )
        
        yield put({
            type: LOAD_INVENTORY_LIST_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_INVENTORY_LIST_FAILURE });
    }
}

function* saveInventoryRecord(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.post,
            `${config.API_URL}/inventory`,
            {
                token: token,
                ...action.payload,
                id: action.payload.key,
            }
        )

        yield put({
            type: SAVE_INVENTORY_RECORD_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: SAVE_INVENTORY_RECORD_FAILURE });
    }
}

function* deleteInventoryRecord(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.delete,
            `${config.API_URL}/inventory?token=${token}&id=${action.payload}`
        )

        if (ret.data === 'OK')
        {
            yield put({
                type: DELETE_INVENTORY_RECORD_SUCCESS,
                payload: action.payload
            });
        } else {
            notification.open({
                message: 'Notifications',
                description: ret.data,
                icon: <Icon type="info-circle" style={{ color: config.primaryColor }}/>
            });
            yield put({ 
                type: DELETE_INVENTORY_RECORD_FAILURE,
                payload: ''
            });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: DELETE_INVENTORY_RECORD_FAILURE });
    }
}

export default function* () {
    yield takeLatest(LOAD_INVENTORY_LIST_REQUEST, loadInventoryList);    
    yield takeEvery(SAVE_INVENTORY_RECORD_REQUEST, saveInventoryRecord);
    yield takeEvery(DELETE_INVENTORY_RECORD_REQUEST, deleteInventoryRecord);
};
