import Inventory from './Inventory/reducers';
import Delivery from './Delivery/reducers';
import Sales from './Sales/reducers';
import Product from './Product/reducers';
import Recipe from './Recipe/reducers';

export default {
    Inventory,
    Delivery,
    Sales,
    Product,
    Recipe
};
