export const LOAD_DELIVERY_DETAILS_REQUEST = 'LOAD_DELIVERY_DETAILS_REQUEST';
export const LOAD_DELIVERY_DETAILS_SUCCESS = 'LOAD_DELIVERY_DETAILS_SUCCESS';
export const LOAD_DELIVERY_DETAILS_FAILURE = 'LOAD_DELIVERY_DETAILS_FAILURE';

export const UPDATE_DELIVERY_STATUS_REQUEST = 'UPDATE_DELIVERY_STATUS_REQUEST';
export const UPDATE_DELIVERY_STATUS_SUCCESS = 'UPDATE_DELIVERY_STATUS_SUCCESS';
export const UPDATE_DELIVERY_STATUS_FAILURE = 'UPDATE_DELIVERY_STATUS_FAILURE';

export const UPDATE_PAYMENT_STATUS_REQUEST = 'UPDATE_PAYMENT_RECEIVED_STATUS_REQUEST';
export const UPDATE_PAYMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_RECEIVED_STATUS_SUCCESS';
export const UPDATE_PAYMENT_STATUS_FAILURE = 'UPDATE_PAYMENT_RECEIVED_STATUS_FAILURE';
