import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    LOAD_SALES_ORDER_LIST_REQUEST,
    LOAD_SALES_ORDER_LIST_SUCCESS,
    LOAD_SALES_ORDER_LIST_FAILURE,
    SELECT_CURRENT_SALES_ORDER_REQUEST,
    SELECT_CURRENT_SALES_ORDER_SUCCESS,
    SELECT_CURRENT_SALES_ORDER_FAILURE,
    UPDATE_READY_STATUS_REQUEST,
    UPDATE_READY_STATUS_SUCCESS,
    UPDATE_READY_STATUS_FAILURE,
    LOAD_PRODUCT_NAMES_REQUEST,
    LOAD_PRODUCT_NAMES_SUCCESS,
    LOAD_PRODUCT_NAMES_FAILURE,
    EDIT_CURRENT_SALES_ORDER_REQUEST,
    EDIT_CURRENT_SALES_ORDER_SUCCESS,
    EDIT_CURRENT_SALES_ORDER_FAILURE,
    ADD_CURRENT_SALES_ORDER_REQUEST,
    ADD_CURRENT_SALES_ORDER_SUCCESS,
    ADD_CURRENT_SALES_ORDER_FAILURE,
    DELETE_SALES_ORDER_REQUEST,
    DELETE_SALES_ORDER_SUCCESS,
    DELETE_SALES_ORDER_FAILURE,
} from './types';
import axios from 'axios';

//var id = 100;
//const newKey = 99999999999999;

function* loadSalesOrderList() {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/sales?token=${token}`
        )
        
        yield put({
            type: LOAD_SALES_ORDER_LIST_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_SALES_ORDER_LIST_FAILURE });
    }
}

function* selectCurrentSalesOrder(action) {
    //no need to load sales order since take from redux
    //need to load sales product from db for selected sales order id
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/salesProduct?token=${token}&id=${action.payload}`
        )
        
        yield put({
            type: SELECT_CURRENT_SALES_ORDER_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: SELECT_CURRENT_SALES_ORDER_FAILURE });
    }
}

function* updateReadyStatus(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.API_URL}/salesProduct`,
            {
                token, 
                ...action
            }
        )
        
        yield put({
            type: UPDATE_READY_STATUS_SUCCESS,
            payload: action
        });
    } catch (err) {
        console.error(err);
        yield put({ type: UPDATE_READY_STATUS_FAILURE });
    }
}

function* loadProductNames(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/productList?token=${token}`
        )
        
        yield put({
            type: LOAD_PRODUCT_NAMES_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_PRODUCT_NAMES_FAILURE });
    }
}

function* editCurrentSalesOrder(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.API_URL}/sales`,
            {
                token,
                ...action.payload,
            }
        )
        
        yield put({
            type: EDIT_CURRENT_SALES_ORDER_SUCCESS,
            payload: ret.data[0]
        });
    } catch (err) {
        console.error(err);
        yield put({ type: EDIT_CURRENT_SALES_ORDER_FAILURE });
    }
}

function* addCurrentSalesOrder(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.post,
            `${config.API_URL}/sales`,
            {
                token,
                ...action.payload,
            }
        )
        
        yield put({
            type: ADD_CURRENT_SALES_ORDER_SUCCESS,
            payload: ret.data[0]
        });
    } catch (err) {
        console.error(err);
        yield put({ type: ADD_CURRENT_SALES_ORDER_FAILURE });
    }
}

function* deleteSalesOrder(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.delete,
            `${config.API_URL}/sales?token=${token}&id=${action.payload}`
        )
        
        yield put({
            type: DELETE_SALES_ORDER_SUCCESS,
            payload: action.payload
        });
    } catch (err) {
        console.error(err);
        yield put({ type: DELETE_SALES_ORDER_FAILURE });
    }
}

export default function* () {
    yield takeLatest(LOAD_SALES_ORDER_LIST_REQUEST, loadSalesOrderList);
    yield takeEvery(SELECT_CURRENT_SALES_ORDER_REQUEST, selectCurrentSalesOrder);
    yield takeEvery(UPDATE_READY_STATUS_REQUEST, updateReadyStatus);
    yield takeLatest(LOAD_PRODUCT_NAMES_REQUEST, loadProductNames);
    yield takeEvery(EDIT_CURRENT_SALES_ORDER_REQUEST, editCurrentSalesOrder);
    yield takeEvery(ADD_CURRENT_SALES_ORDER_REQUEST, addCurrentSalesOrder);
    yield takeEvery(DELETE_SALES_ORDER_REQUEST, deleteSalesOrder);
};
