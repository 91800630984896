export const LOAD_SALES_ORDER_LIST_REQUEST = 'LOAD_SALES_ORDER_LIST_REQUEST';
export const LOAD_SALES_ORDER_LIST_SUCCESS = 'LOAD_SALES_ORDER_LIST_SUCCESS';
export const LOAD_SALES_ORDER_LIST_FAILURE = 'LOAD_SALES_ORDER_LIST_FAILURE';

export const SHOW_EDIT_SALES_ORDER = 'SHOW_EDIT_SALES_ORDER';
export const SHOW_ADD_SALES_ORDER = 'SHOW_ADD_SALES_ORDER';

export const SELECT_CURRENT_SALES_ORDER_REQUEST = 'SELECT_CURRENT_SALES_ORDER_REQUEST';
export const SELECT_CURRENT_SALES_ORDER_SUCCESS = 'SELECT_CURRENT_SALES_ORDER_SUCCESS';
export const SELECT_CURRENT_SALES_ORDER_FAILURE = 'SELECT_CURRENT_SALES_ORDER_FAILURE';

export const EDIT_CURRENT_SALES_ORDER_REQUEST = 'EDIT_CURRENT_SALES_ORDER_REQUEST';
export const EDIT_CURRENT_SALES_ORDER_SUCCESS = 'EDIT_CURRENT_SALES_ORDER_SUCCESS';
export const EDIT_CURRENT_SALES_ORDER_FAILURE = 'EDIT_CURRENT_SALES_ORDER_FAILURE';

export const ADD_CURRENT_SALES_ORDER_REQUEST = 'ADD_CURRENT_SALES_ORDER_REQUEST';
export const ADD_CURRENT_SALES_ORDER_SUCCESS = 'ADD_CURRENT_SALES_ORDER_SUCCESS';
export const ADD_CURRENT_SALES_ORDER_FAILURE = 'ADD_CURRENT_SALES_ORDER_FAILURE';

export const CANCEL_SALES_PRODUCT_RECORD = 'CANCEL_SALES_PRODUCT_RECORD';
export const EDIT_SALES_PRODUCT_RECORD = 'EDIT_SALES_PRODUCT_RECORD';

export const DELETE_SALES_PRODUCT_RECORD = 'DELETE_SALES_PRODUCT_RECORD';
export const SAVE_SALES_PRODUCT_RECORD = 'SAVE_SALES_PRODUCT_RECORD';
export const ADD_SALES_PRODUCT_RECORD = 'ADD_SALES_PRODUCT_RECORD';

export const UPDATE_READY_STATUS_REQUEST = 'UPDATE_READY_STATUS_REQUEST';
export const UPDATE_READY_STATUS_SUCCESS = 'UPDATE_READY_STATUS_SUCCESS';
export const UPDATE_READY_STATUS_FAILURE = 'UPDATE_READY_STATUS_FAILURE';

export const LOAD_PRODUCT_NAMES_REQUEST = 'LOAD_PRODUCT_NAMES_REQUEST';
export const LOAD_PRODUCT_NAMES_SUCCESS = 'LOAD_PRODUCT_NAMES_SUCCESS';
export const LOAD_PRODUCT_NAMES_FAILURE = 'LOAD_PRODUCT_NAMES_FAILURE';

export const DELETE_SALES_ORDER_REQUEST = 'DELETE_SALES_ORDER_REQUEST';
export const DELETE_SALES_ORDER_SUCCESS = 'DELETE_SALES_ORDER_SUCCESS';
export const DELETE_SALES_ORDER_FAILURE = 'DELETE_SALES_ORDER_FAILURE';
