import config from '../../config';
import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
    LOAD_DELIVERY_DETAILS_REQUEST,
    LOAD_DELIVERY_DETAILS_SUCCESS,
    LOAD_DELIVERY_DETAILS_FAILURE,
    UPDATE_DELIVERY_STATUS_REQUEST,
    UPDATE_DELIVERY_STATUS_SUCCESS,
    UPDATE_DELIVERY_STATUS_FAILURE,
    UPDATE_PAYMENT_STATUS_REQUEST,
    UPDATE_PAYMENT_STATUS_SUCCESS,
    UPDATE_PAYMENT_STATUS_FAILURE
} from './types';
import axios from 'axios';

function* loadLatestDelivery(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.get,
            `${config.API_URL}/delivery?token=${token}&startDate=${action.startDate}&endDate=${action.endDate}&payment_received=${action.payment_received}&delivered=${action.delivered}`
        )

        yield put({
            type: LOAD_DELIVERY_DETAILS_SUCCESS,
            payload: ret.data
        });
    } catch (err) {
        console.error(err);
        yield put({ type: LOAD_DELIVERY_DETAILS_FAILURE });
    }
}

function* updateDeliveryStatus(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.API_URL}/delivery`,
            action
        )

        yield put({
            type: UPDATE_DELIVERY_STATUS_SUCCESS,
            payload: action
        });
    } catch (err) {
        console.error(err);
        yield put({ type: UPDATE_DELIVERY_STATUS_FAILURE });
    }
}

function* updatePaymentStatus(action) {
    try {
        var token = window.localStorage.getItem("token");
        const ret = yield call (
            axios.put,
            `${config.API_URL}/delivery`,
            action
        )

        yield put({
            type: UPDATE_PAYMENT_STATUS_SUCCESS,
            payload: action
        });
    } catch (err) {
        console.error(err);
        yield put({ type: UPDATE_PAYMENT_STATUS_FAILURE });
    }
}



export default function* () {
    yield takeLatest(LOAD_DELIVERY_DETAILS_REQUEST, loadLatestDelivery);
    yield takeEvery(UPDATE_DELIVERY_STATUS_REQUEST, updateDeliveryStatus);
    yield takeEvery(UPDATE_PAYMENT_STATUS_REQUEST, updatePaymentStatus);
};
